import { Button, Card, Col, Container, Row } from "react-bootstrap"
import NavBar from "../../components/NavBar/NavBar"
import "./Home.scss"
import { useParams } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import Footer from "../../components/Footer/Footer"
import steps from '../Tour/steps.json'
import { arrayUnique } from "../../utils/utils"
import { useEffect, useState } from "react"

export default function Home() {
    let { step, lang, factory: selectedFactory } = useParams();
    const { t, i18n } = useTranslation();
    const [tours, setTours] = useState<string[]>([])
    const [factory, setFactory] = useState<string>('M12')

    useEffect(() => {
        let factory = selectedFactory && Object.keys(steps).includes(selectedFactory) ? selectedFactory : 'M12'
        const list: string[] = []

        // @ts-ignore
        steps[factory].steps.forEach(el => {
            list.push(...(el.tours))
        })

        setTours(arrayUnique(list))
        setFactory(factory)

    }, [selectedFactory])

    return (
        <>
            <NavBar></NavBar>
            <Container className="my-4">
                <h1>Factory Tour</h1>
            </Container>
            <Container fluid>
                <Row className="justify-content-center tour-card">
                    {tours.map((el, index) => {
                        return (
                            <Col className={`tour-col ${index % 2 ? 'col-odd' : 'col-even'} ${el} tour-card-bg ${tours.length === 1 ? 'tall-tour-card' : ''}`}
                                style={{ position: 'relative' }} md={4} key={el}>
                                <div className="tour-card-layer"></div>
                                <Card style={{ backgroundColor: 'transparent' }} className={``}>
                                    <Card.Body className="tour-card-body">
                                        <Card.Title className="tour-card-title">{t(el)}</Card.Title>
                                        <Card.Text>
                                        </Card.Text>
                                        <Button variant="light" href={`/${lang}/${factory}/${el}`}>
                                            {t('goto-tour')}
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
            <Footer></Footer>
        </>
    )
}
