import { Breadcrumb, Button, ButtonGroup, Col, Container, Row } from "react-bootstrap";
import NavBar from "../../components/NavBar/NavBar";
import BreadcrumbContainer from "../../components/BreadcrumbContainer/BreadcrumbContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GuideList from "../../components/GuideList/GuideList";
import { GuidePage } from "../../types";
import Room from "../../components/Room/Room";
import { useEffect, useState } from "react";
import steps from "./steps.json";
import itTour from "./content-it.json";
import enTour from "./content-en.json";
import Timeline from "../../components/Timeline/Timeline";
import Footer from "../../components/Footer/Footer";
import { useTranslation } from 'react-i18next';

export default function () {
    const navigate = useNavigate()
    const location = useLocation();
    const { t, i18n } = useTranslation();

    let { step, lang, factory, tourType: type } = useParams();

    const [currentGuidePage, setCurrentGuidePage] = useState<GuidePage | undefined>(undefined);
    const [curr, setCurr] = useState(0);
    const [pages, setPages] = useState<GuidePage[]>([])
    const [ready, setReady] = useState<boolean>(false)
    const [tourType, setTourType] = useState<string>(type || 'steps')

    useEffect(() => {
        let tourTypes = new Set()


        // @ts-ignore
        steps[factory].steps.forEach(el => {
            el.tours.forEach((t: string[]) => {
                tourTypes.add(t)
            });
        });

        const tourTypeT = tourType;
        let selectedTourType = tourType;

        if (!tourTypes.has(`${tourTypeT}`)) {
            if (['steps', 'tour'].includes(tourTypeT)) {
                selectedTourType = 'steps'
            } else {
                window.location.replace(`/${lang}/${factory}/tour/${step}`)
                return
            }
        }

        //@ts-ignore
        let tourPages: GuidePage[] = lang === 'it' ? itTour[factory].steps : enTour[factory].steps

        tourPages = tourPages.map((el, i) => {
            return { ...el, stepN: (i + 1) }
        })

        if (selectedTourType !== 'steps') {
            //@ts-ignore
            const stepsHrefs = steps[factory].steps.filter(el => el.tours.includes(tourTypeT)).map(el => el.href)
            tourPages = tourPages.filter(el => stepsHrefs.includes(el.href))
        }

        if (step) {
            const currentPage = tourPages.find(el => el.href === step)
            setCurrentGuidePage(currentPage);

            if (currentPage) {
                let currentGuideIdx = tourPages.indexOf(currentPage);
                setCurr(currentGuideIdx)
            } else {
                navigate('/404')
                return
            }
        }

        setTourType(selectedTourType)
        setPages(tourPages)
        setReady(true)
    }, [])

    function getNext(left: boolean) {
        let c = curr;
        if (left && curr > 0) {
            c = curr - 1;
        }

        if (!left && curr < (pages.length - 1)) {
            c = curr + 1;
        }

        return pages[c].href;
    }

    return (
        <>
            {ready ? (
                <>
                    <NavBar></NavBar>
                    <BreadcrumbContainer>
                        <Breadcrumb.Item href={`/${lang}/${factory}`}>Home</Breadcrumb.Item>
                        {currentGuidePage ? (
                            <>
                                {tourType !== 'steps' && (
                                    <Breadcrumb.Item href={`/${lang}/${factory}/${tourType}`}>
                                        {t(tourType)}
                                    </Breadcrumb.Item>
                                )}

                                <Breadcrumb.Item active>
                                    {currentGuidePage.title}
                                </Breadcrumb.Item>
                            </>
                        ) : (
                            <Breadcrumb.Item active>
                                {t(tourType)}
                            </Breadcrumb.Item>
                        )}
                    </BreadcrumbContainer>
                    {pages.length && (
                        <Container>
                            {step ? <div>
                                <Row style={{ marginBottom: 100 }}>
                                    {tourType !== 'steps' && (
                                        <Col xs={1}>
                                            <Timeline items={pages} size="small" />
                                        </Col>
                                    )}
                                    <Col xs={tourType === 'steps' ? 12 : 11}>
                                        {currentGuidePage && <Room page={currentGuidePage}></Room>}
                                        <div className="m-4">&nbsp;</div>
                                        <div className="d-flex justify-content-center">
                                            {tourType !== 'steps' && (
                                                <ButtonGroup aria-label="Basic example">
                                                    {curr > 0 && (
                                                        <Button variant="ligth" className="text-center" href={getNext(true)}>
                                                            <Row>
                                                                <Col><i className="gg-push-chevron-left"></i></Col>
                                                                <Col><span>{t('back')}</span></Col>
                                                            </Row>
                                                        </Button>
                                                    )}
                                                    {curr === (pages.length - 1) ? (
                                                        // last page
                                                        <Button variant="dark" className="text-center"
                                                            href={`/${lang}/${factory}`}>
                                                            <Row>
                                                                <Col><span>{t('end')}</span></Col>
                                                            </Row>
                                                        </Button>
                                                    ) : (
                                                        <Button variant="dark" className="text-center"
                                                            href={getNext(false)}
                                                            disabled={curr === (pages.length - 1)}>
                                                            <Row>
                                                                <Col><span>{t('next')}</span></Col>
                                                                <Col><i className="gg-push-chevron-right"></i></Col>
                                                            </Row>
                                                        </Button>
                                                    )}

                                                </ButtonGroup>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div> : <GuideList pages={pages}></GuideList>}
                        </Container>
                    )}
                    <Footer></Footer>
                </>) : <div></div>}
        </>
    )
}