import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { supportedLang, userRedirect } from "../../utils/utils";
import { useTranslation } from 'react-i18next';
import steps from '../../pages/Tour/steps.json'

const DEFAULT_FACTORY = 'M12'

export default function ({ children }: { children: JSX.Element }) {
    const params = useParams();
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const [valid, setRendered] = useState<boolean>(false)

    useEffect(() => {
        const { lang = '', factory } = params;

        if (!supportedLang.includes(lang)) {
            navigate(userRedirect())
            return;
        }

        if (factory) {
            // @ts-ignore
            // If a factory is given, check if it is a valid factory
            if (typeof steps[factory] === 'undefined') {
                navigate('/404')
                return
            }
        } else {
            navigate(`/${lang}/${DEFAULT_FACTORY}`)
        }

        i18n.changeLanguage(lang)
        setRendered(true)
    }, [params])

    return (
        <>{valid && children}</>
    )
}