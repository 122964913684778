export default function () {
    return (
        <div className="container mt-5">
            <div className="row d-flex justify-content-center">
                <div className="col-md-6 text-center">
                    <h1>Oops!</h1>
                    <p className="lead">The page you requested was not found.</p>
                    <a href="/" className="btn btn-primary btn-dark">Go Home</a>
                </div>
            </div>
        </div>
    )
}