import { Breadcrumb, Container } from "react-bootstrap";
import "./BreadcrumbContainer.scss";

export default function ({ children }: { children: JSX.Element[] }) {
    return (<div className="breadcumb-cont">
        <Container>
            <Breadcrumb>
                {children}
            </Breadcrumb>
        </Container>
    </div>)
}