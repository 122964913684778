import { Breadcrumb, Button, Card, Col, Container, Row } from "react-bootstrap";
import { GuidePage } from "../../types";
import Timeline from "../Timeline/Timeline";

export default function ({ pages }: { pages: GuidePage[] }) {
    return (
            <Row>
                <Timeline items={pages}/>
            </Row>
    )
}