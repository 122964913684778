import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home/Home';
import Tour from './pages/Tour/Tour';
import './App.scss';
import PathValidator from './components/PathValidator/PathValidator';
import NotFound from './pages/NotFound/NotFound';
import { userRedirect } from './utils/utils';

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path='/:lang'>
                        <Route path='/:lang/' element={<PathValidator><Home /></PathValidator>} />
                        <Route path='/:lang/:factory' element={<PathValidator><Home /></PathValidator>}></Route>
                        <Route path='/:lang/:factory/:tourType' element={<PathValidator><Tour /></PathValidator>}></Route>
                        <Route path='/:lang/:factory/:tourType/:step' element={<PathValidator><Tour /></PathValidator>}></Route>
                    </Route>
                    <Route path='/404' element={<NotFound />} />
                    <Route path="*" element={<Navigate to={userRedirect(navigator.language, '/')} />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
