import { Breadcrumb, Button, Card, Col, Container, ProgressBar, Row } from "react-bootstrap";
import { GuidePage } from "../../types";
import "./Room.scss"
import { MouseEventHandler, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';

export default function ({ page }: { page: GuidePage }) {
    const [playing, setPlaying] = useState(false)
    const [progress, setProgress] = useState(0)
    const [audioLoaded, setAudioLoaded] = useState<boolean>(false)
    const audio = useRef<HTMLAudioElement>(null)
    const { t, i18n } = useTranslation();

    function onPlayClicked() {
        if (!audio.current) return;

        if (audio.current.paused) {
            audio.current?.play();
            setPlaying(true)
        } else {
            audio.current?.pause();
            setPlaying(false)
        }
    }

    function onAudioTimeUpdate() {
        if (!audio.current) return

        const curr = audio.current.currentTime;
        setProgress((curr / audio.current.duration) * 100);
    }

    function onProgressBarClick(e: MouseEventHandler<HTMLDivElement>) {
        if (!audio.current) return;

        //@ts-ignore
        const nativeEvent: MouseEvent = e.nativeEvent;

        //@ts-ignore
        const progressBar = document.querySelector('.progress')!
        const divWidth = progressBar.getBoundingClientRect().width;

        const perc = nativeEvent.offsetX / divWidth;
        const audioLength = audio.current.duration;

        audio.current.currentTime = audioLength * perc;
    }

    function onProgressBarTouch(e: MouseEventHandler<HTMLDivElement>) {
        //@ts-ignore
    }



    return (
        <Container className="my-4">
            <h1>
                <div className="d-flex align-items-center" style={{ gap: 20 }}>
                    <i className={playing ? "fa-solid fa-volume-high" : "fa-solid fa-volume-off"} style={{ fontSize: 25, width: 20 }}></i>
                    <span>
                        {page.title}
                    </span>
                </div>
            </h1>

            <p className="my-3 pb-4">
                {page.content}
            </p>

            <div className="mt-4"></div>
            <Row className="d-flex justify-content-center">
                <Col className="d-flex justify-content-center">
                    <Button variant="dark" className="inline-block" onClick={onPlayClicked}>
                        <Row className="d-flex align-items-center">
                            <Col>{playing ? <i className="gg-play-pause mx-2"></i> : <i className="gg-play-button"></i>}</Col>
                            <Col className="p-0"><div style={{ width: 1, height: 20, backgroundColor: 'grey' }}></div></Col>
                            <Col>{playing ? t('pause') : t('play')}</Col>
                        </Row>
                    </Button>
                </Col>
            </Row>

            <div className="mt-3"></div>

            <Row className="d-flex justify-content-center">
                <Col md={4}>
                    <div className="bg-dark px-2 py-2">
                        {/* @ts-ignore */}
                        <ProgressBar now={progress} onClick={onProgressBarClick} onTouchStartCapture={onProgressBarTouch}>
                        </ProgressBar>
                    </div>
                </Col>
            </Row>

            <audio src={`/media/guides/${page.audio}`}
                preload="auto"
                controls={false}
                autoPlay={false}
                ref={audio}
                onLoad={() => setAudioLoaded(true)}
                onTimeUpdate={onAudioTimeUpdate}
                onEnded={() => setPlaying(false)}
            ></audio>
        </Container>
    )
}