import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "brief-tour": "Brief Tour",
            "general-tour": "General Tour",
            "tour": "General Tour",
            "medium-tour": "General Tour",
            "extended-tour": "Extended Tour",
            "goto-tour": "Start",
            "back": "Back",
            "next":"Next",
            "play": "Play" ,    
            "pause": "Pause",
            "step": "Stage",
            "contacts": "Contacts",
            "end": "End tour"
        }
    },
    it: {
        translation: {
            "general-tour": "Tour Generale",
            "tour": "Tour Generale",
            "brief-tour": "Tour Breve",
            "medium-tour": "Tour Generale",
            "extended-tour": "Tour Completo",
            "goto-tour": "Vai",
            "back": "Indietro",
            "next":"Avanti",
            "play": "Riproduci" ,    
            "pause": "Pausa",
            "step": "Tappa",
            "contacts": "Contatti",
            "end": "Fine tour"
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "it", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;