import { Col, Container, Row } from "react-bootstrap"
import "./Footer.scss"
import { useTranslation } from 'react-i18next';

export default function () {
    const { t, i18n } = useTranslation();
    return (
        <div></div>
        // <div >
        //     <footer>
        //         <Container>
        //             <Row>
        //                 <Col md={4}>
        //                     <div>
        //                         {/* <img src="/media/mitsubishi.png" alt="Mistsubishi" loading="lazy" /><br /><br /> */}
        //                         <small>
        //                             <b >Mitsubishi Electric Hydronics &amp; IT Cooling Systems</b><br />
        //                             S.p.A.Società con socio unico soggetta ad altrui attività di direzione e coordinamento. <br /><br />
        //                             <b>Capitale sociale</b>: Euro 10.000.000,00 i.v <br />
        //                             R.E.A. 300809/Vicenza <br />
        //                             <b>Codice fiscale e partita IVA</b>: IT 02603430139
        //                         </small>
        //                     </div>
        //                 </Col>
        //                 <Col md={8}>
        //                     <div className="mb-4 mt-4 md-mt-0">
        //                         <b>{t('contacts')}</b>
        //                     </div>
        //                     <div>
        //                         <small>
        //                             <b>Headquarters</b>: Via Caduti di Cefalonia 1, 36061 Bassano del Grappa (VI) - Italy <br />
        //                             <b>Administrative Offices</b>: via Valsugana 98 - 36022 Cassola (VI) - Italy <br /><br />
        //                             <b>Tel</b>: (+39) 0424509500 - Fax (+39) 0424 509509 <br />
        //                             <b>PEC</b>: melcohit@pec.melcohit.com <br />
        //                         </small>
        //                     </div>
        //                 </Col>
        //             </Row>
        //         </Container>
        //     </footer>
        // </div>
    )
}