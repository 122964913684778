export const supportedLang = ['it', 'en'];

export function userRedirect(toLang = (navigator.language || 'en'), pathname?: string) {
    if (!pathname) {
        pathname = window.location.pathname;
    }

    pathname = pathname.replace(/\/$/, '').replace(/^\//, '');

    let splittedPath = pathname.split('/');
    const lang = splittedPath[0];

    if (lang === toLang) {
        return '/' + splittedPath.join('/');
    }

    if (supportedLang.includes(lang)) {
        splittedPath = splittedPath.slice(1)
    }

    if (toLang === 'it' || toLang.startsWith('it-')) {
        return '/it/' + splittedPath.join('/');
    }

    return '/en/' + splittedPath.join('/');
}

export const arrayUnique = <T>(arr: T[]): T[] => {
    const s = new Set<T>()
    arr.forEach(el => s.add(el))

    return Array.from(s)
}