import { Card, Col, Row } from "react-bootstrap"
import "./Timeline.scss"
import { GuidePage } from "../../types"
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from "react-router-dom";

export default function ({ items, size = 'default' }: { items: GuidePage[]; size?: 'default' | 'small' }) {
    const location = useLocation();
    const params = useParams();
    const { t, i18n } = useTranslation();
    const tourType = params.tourType

    function getLinkTo(h: string) {
        return `/${params.lang}/${params.factory}/${tourType}/${h}`
    }

    return (
        <div className={`it-timeline tl-${tourType}`}>
            <div className={`it-timeline-wrapper ${size === 'small' ? 'small' : ''}`}>
                <Row>
                    {items.map((el, i) => {
                        return (
                            <Col xs={12} key={getLinkTo(el.href)}>
                                <div className="timeline-element">
                                    <a href={getLinkTo(el.href)} style={{ textDecoration: 'none' }}>
                                        <div className={`it-pin-wrapper ${location.pathname === getLinkTo(el.href) ? 'it-evidence' : ''}`}>
                                            {size === 'small' ? (<div className="pin-icon-small"></div>) : (<div className="pin-icon"></div>)}

                                            {size !== 'small' && <div className="pin-text text-decoration-underline" ><span>{t('step')} {el.stepN}</span></div>}
                                        </div>
                                    </a>
                                    {size === 'small' ? <div style={{ height: 30 }}></div> : (
                                        <a href={getLinkTo(el.href)} style={{ textDecoration: 'none' }}>
                                            <div className="card-wrapper">
                                                <Card>
                                                    <Card.Body>
                                                        <Card.Title>
                                                            {el.title}
                                                        </Card.Title>
                                                        <Card.Text>
                                                            {el.content?.slice(0, 300)}...
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </a>
                                    )}
                                </div>
                            </Col>

                        )
                    })}
                </Row>
            </div >
        </div>
    )
}