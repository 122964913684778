import { Container, Nav, Navbar } from "react-bootstrap";
import "./NavBar.scss";
import { useLocation, useParams } from "react-router-dom";
import { userRedirect } from "../../utils/utils";

export default function NavBar() {
    const { lang, factory = 'M12' } = useParams()
    
    return (
        <Navbar expand="lg" className="bg-white" bg="light">
            <Container>
                <Navbar.Brand href={`/${lang}/${factory}`} className="">
                    <div className="brand">
                        <img src="/media/melcohit-logo.png" width="210" alt="" />
                        {/* <div className="separator"></div>
                        <span className="text-black px-2"><b>Italy</b></span> */}
                    </div>
                </Navbar.Brand>
                <Navbar.Text>
                    <a href={userRedirect(lang === 'it' ? 'en' : 'it')} style={{ textDecoration: 'none' }}>
                        <img src={`/media/${lang}.png`} alt={`${lang} flag`} width={20} />
                    </a>
                </Navbar.Text>
            </Container>
        </Navbar>
    )
}